import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IStudent } from '@model/interfaces/student';
import { Observable } from 'rxjs';

export const emptyStudent: IStudent = {
    Archived: false,
    ClassroomId: 0,
    Id: 0,
    AuthUserId: 0,
    StudentLoginId: 0,
    StartingBlendingSectionId: 0,
    StartingSonidosSectionId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class StudentService extends BaseService<IStudent> {
    constructor(public http: HttpClient) {
        super('/students', http);
    }

    getEmptyStudent(): IStudent {
        return { ...emptyStudent };
    }

    createStudent(student: IStudent): Observable<number> {
        return this.http.put<number>('/students/create', student);
    }

    updateStudent(student: IStudent): Observable<number> {
        return this.http.put<number>('/students/update', student);
    }

    studentHasFinishedSonidos(studentId: number):Observable<boolean> {
        return this.http.get<boolean>(`/students/${studentId}/hasFinishedSonidos`);
    }

    studentHasFinishedBlending(studentId: number): Observable<boolean> {
        return this.http.get<boolean>(`/students/${studentId}/hasFinishedBlending`);
    }

    archiveSelectedStudents(studentIds: number[]): Observable<void> {
        return this.http.put<void>('/students/archive-selected-students', studentIds);
    }
}
