import { Component } from '@angular/core';
import { AuthStudentService } from './../services/auth-student.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { throwError } from 'rxjs';
import { AuthService, MtAuthGuard } from '@mt-ng2/auth-module';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { IStudentClassroomLoginParams } from '../interfaces/student-classroom-login-params';
import { IStudentLoginImage } from '../interfaces/student-login-image';
import { IStudentClassroomLoginResponse } from '../interfaces/student-classroom-login-response';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-student-login',
    styleUrls: ['./student-login.component.less'],
    templateUrl: './student-login.component.html',
})
export class StudentLoginComponent {
    logoFull: string;
    studentId: number;
    loginImages: IStudentLoginImage[];
    returnUrl = '';

    constructor(private authStudentService: AuthStudentService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private environmentService: EnvironmentService,
        private router: Router,
    private title: Title) {
        this.logoFull = `${this.environmentService.config.assetsPath}logo-full.png`;
    }

    ngOnInit(): void {
        this.title.setTitle("Student login");
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }


    cancel(): void {
        this.studentId = undefined;
        this.loginImages = undefined;
    }

    loginToClassroom(loginParams: IStudentClassroomLoginParams): void {
        this.authStudentService.classroomLogin(loginParams.Classroom, loginParams.Nickname).subscribe(
            (response: IStudentClassroomLoginResponse) => {
                this.studentId = response.StudentId;
                this.loginImages = response.Images;
            },
            (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 418) {
                    this.notificationsService.error('Classroom or Nickname is not correct');
                } else if (errorResponse.status === 403) {
                    if (errorResponse.error && typeof errorResponse.error === 'string') {
                        // if error message (i.e. "account is locked") then display it
                        this.notificationsService.error(errorResponse.error);
                    } else {
                        // else rethrow the error
                        throwError(errorResponse).subscribe();
                    }
                }
            },
        );
    }

    loginWithImage(imageId: number): void {
        this.authStudentService.imageLogin(this.studentId, imageId).subscribe(
            () => {
                if (this.returnUrl) {
                    void this.router.navigateByUrl(this.returnUrl);
                } else {
                    void this.router.navigate(['/landing']);
                }
            },
            (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 418) {
                    this.authStudentService.getStudentLoginImages(this.studentId).subscribe((loginImages: IStudentLoginImage[]) => {
                        this.notificationsService.error('Incorrect image selected. Try again.');
                        this.loginImages = loginImages;
                    });
                } else if (errorResponse.status === 403) {
                    if (errorResponse.error && typeof errorResponse.error === 'string') {
                        this.notificationsService.error(errorResponse.error);
                    } else {
                        throwError(errorResponse).subscribe();
                    }
                }
            },
        );
    }
}
