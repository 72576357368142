<div class="student-login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div [class.classroom-login-box-body]="!studentId"
        [class.image-login-box-body]="studentId">
        <app-student-classroom-login *ngIf="!studentId" (onClassroomLogin)="loginToClassroom($event)"></app-student-classroom-login>
        <app-student-image-login *ngIf="studentId" [loginImages]="loginImages" (onImageSelected)="loginWithImage($event)"></app-student-image-login>
        <button *ngIf="studentId" type="button" (click)="cancel()" class="btn btn-primary btn-flat inline-block">
            Cancelar
        </button>
    </div>
</div>
