import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@environment';
import { IStudentLoginImage } from 'student-portal/custom-login/interfaces/student-login-image';

@Component({
    selector: 'app-student-image-login',
    styleUrls: ['./../student-login.component.less', './student-image-login.component.less'],
    templateUrl: './student-image-login.component.html',
})
export class StudentImageLoginComponent {
    @Output('onImageSelected') onImageSelected: EventEmitter<number> = new EventEmitter<number>();
    @Input() loginImages: IStudentLoginImage[];

    imagePath: string;


    ngOnInit(): void {
        this.imagePath = `${environment.baseApiUrl}/${environment.docPath}images/`;
    }

    selectImage(imageId: number): void {
        this.onImageSelected.emit(imageId);
    }
}
