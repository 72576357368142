// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    apiVersion: '1',
    appName: 'Estrellita',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    baseApiUrl: '',
    debug: {
        active: false,
        skipAudio: false,
        skipInstructions: false,
    },
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'assets/images/',
    logger: false,
    msAuthConfig: null,
    production: false,
    siteName: 'estrellita',
    soundPath: 'assets/sounds/',
    submitHelpUrl: 'http://submit-help.staging.milesapp.com',
    laVeredaLoginUrl: 'https://teachers.estrellita.com/my-account/',
    laVeredaLandingUrl: 'https://teachermaint.wpengine.com/landing-page/',
    repiteUrls: {
        female: 'media/blending/sentences/_instructions/Repite.m4a',
        male: 'media/lunita/sentences/_instructions/Repite.mp3',
    },
    membershipUrl: 'https://estrellita.com/membership/',
    studentDomain: "estudiante.estrellita.staging.milesapp.com",
    adminDomain: "maestro.estrellita.staging.milesapp.com"
};
