import { Component, ErrorHandler, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Courses } from '@model/enums/courses.enum';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { StudentService } from 'admin-portal/classrooms/students/services/student.service';
import { AuthStudentService } from '../services/auth-student.service';

@Component({
    selector: 'app-student-access',
    template: '',
})
export class StudentAccessComponent implements OnInit {
    key: string;
    badKeyError = `Oops something went wrong!
    - Probably the key has expired or student is not active anymore
    - Or you did something you weren't supposed to do.`;

    constructor(
        private studentAuthService: AuthStudentService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private studentService: StudentService,
        private globalErrorHandler: ErrorHandler,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.key = params.key;
        });
        this.studentAuthService.studentAccess(this.key).subscribe(
            (resp) => {
                this.success();
                this.studentService.getById(resp.UserDetails.Id).subscribe((s) => {
                    const courses: number[] = s.Classroom.Teacher.ActivationCodes.flatMap(ac => ac.Program.ProgramCourses.map(pc => pc.CourseId));
                    if (courses.length === 1 && courses[0] === Courses.Sonidos) {
                        void this.router.navigate(['/noaccess']);
                    } else {
                        void this.router.navigate(['/home']);
                    }
                });
            },
            (error) => {
                if (error.status === 418) {
                    this.notificationsService.error('Access link is invalid');
                    console.error(this.badKeyError);
                } else if (error.status === 400) {
                    if (error.error) {
                        if (typeof error.error === 'string') {
                            this.error(error.error as string);
                        } else if (typeof error.error?.ModelState?.Service === 'string') {
                            this.error(error.error.ModelState.Service as string);
                        } else {
                            this.globalErrorHandler.handleError(error);
                        }
                    } else {
                        this.error('Something went wrong, Please login manually');
                        console.error(this.badKeyError);
                    }
                }
            },
        );
    }


    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Welcome!');
    }
}
