import { NgModule } from '@angular/core';

import { NavModule } from '@mt-ng2/nav-module';

@NgModule({
	declarations: [],
	imports: [NavModule],
	exports: [NavModule],
})
export class AdminPortalComponentsModule {}
