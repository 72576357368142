
import { environment } from '@environment';
import { appPaths } from 'student-portal/default-routes/app-paths.library';
import { ILoginConfig, LoginComponentLink } from '@mt-ng2/login-module';

// the api key below is for BreckDemo and should be updated
export const LoginConfigOverride: ILoginConfig = {
    googleAuthConfig: environment.googleApiKey ? { googleApiKey: environment.googleApiKey } : null,
    messageOverrides: {
        failedPattern: 'Valid passwords must be at least 8 characters, have a capital letter and number.',
    },
    microsoftAuthConfig: environment.msAuthConfig ? environment.msAuthConfig : null,
    passwordPattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&]{8,}',
    useAssetsFolderForImages: true,
    loginComponentLinks: [
        new LoginComponentLink(`<a class="account-expired" href="${environment.membershipUrl}" target="_blank">Account expired? Click here.</a>`, '/login'),
        new LoginComponentLink(`<a href="${environment.laVeredaLoginUrl}" target="_blank">New account or forgot password</a>`, '/login'),
        new LoginComponentLink(`<a href="http://${environment.studentDomain}">For students, click here to log in.</a>`, '')
    ]

    /*
        Below are the remaining possible options for configuring the login component with their default values.
        Also see http://breckdemo.testing.milesapp.com/#/forms/login for a demonstraction
        of each configuration option.
    */

    // allowRememberMe: false,
    // forgotPasswordMessage: 'Provide the email associated with your account and click Submit. An email will be sent with a link to reset your password.',
    // hideRegularSignIn: false,
    // loginComponentLinks: [
    //     new LoginComponentLink(
    //         '<a>I forgot my password</a>',
    //         '/forgotpassword',
    //     ),
    // ],
    // message: 'Sign in to start your session',
    // signInButtonText: 'Sign In',
};
