import { Component, EventEmitter, inject, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@environment';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IStudentClassroomLoginParams } from './../../interfaces/student-classroom-login-params';

@Component({
    selector: 'app-student-classroom-login',
    styleUrls: ['./../student-login.component.less'],
    templateUrl: './student-classroom-login.component.html',
})
export class StudentClassroomLoginComponent {
    @Output('onClassroomLogin') onClassroomLogin: EventEmitter<IStudentClassroomLoginParams> = new EventEmitter<IStudentClassroomLoginParams>();
    classroomLoginForm: UntypedFormGroup;


    adminDomain = `http://${environment.adminDomain}`;

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        if (!this.classroomLoginForm) {
            this.classroomLoginForm = new UntypedFormGroup({});
        }
        this.classroomLoginForm.addControl('classroom', new UntypedFormControl('', (control) => Validators.required(control)));
        this.classroomLoginForm.addControl('nickname', new UntypedFormControl('', (control) => Validators.required(control)));
    }

    showClassroomRequiredError(): boolean {
        const control = this.classroomLoginForm.get('classroom');
        return control.touched && control.hasError('required');
    }

    showNicknameRequiredError(): boolean {
        const control = this.classroomLoginForm.get('nickname');
        return control.touched && control.hasError('required');
    }

    loginToClassroom(): void {
        if (this.classroomLoginForm.valid) {
            const values = this.classroomLoginForm.value;
            this.onClassroomLogin.emit({
                Classroom: values.classroom,
                Nickname: values.nickname,
            });
        } else {
            markAllFormFieldsAsTouched(this.classroomLoginForm);
        }
    }

}
