<p class="login-box-msg login-box-msg-font">¡Bienvenido!</p>
<form *ngIf="classroomLoginForm" [formGroup]="classroomLoginForm" (submit)="loginToClassroom()">
    <div class="form-group has-feedback" [class.has-error]="showNicknameRequiredError()">
        <input type="text" autofocus class="form-control" placeholder="Introduzca su nombre" formControlName="nickname" />
        <span class="fa fa-user form-control-feedback"></span>
        <div *ngIf="showNicknameRequiredError()" class="small errortext" [style.position]="'absolute'">Nickname is required</div>
    </div>
    <div class="form-group has-feedback" [class.has-error]="showClassroomRequiredError()">
        <input type="text" class="form-control" placeholder="Ingrese el nombre de su clase" formControlName="classroom" />
        <span class="fa fa-graduation-cap form-control-feedback"></span>
        <div *ngIf="showClassroomRequiredError()" class="small errortext" [style.position]="'absolute'">Classroom is required</div>
    </div>
    <div class="block-parent">
        <button [disabled]="!classroomLoginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block">
            Iniciar Sesión
        </button>
    </div>
</form>
<br />
    <a [href]="adminDomain">For teachers, click here to log in.</a>
