import { NgModule } from '@angular/core';

import { StudentPortalSharedModule } from '@common/shared.module';
import { StudentPortalFeatureModule } from '@common/feature.module';

import { StudentLoginComponent } from './student-login/student-login.component';
import { StudentClassroomLoginComponent } from './student-login/classroom/student-classroom-login.component';
import { StudentImageLoginComponent } from './student-login/image/student-image-login.component';
import { StudentAccessComponent } from './student-access/student-access.component';
import { StudentNoAccessComponent } from './student-no-access/student-no-access.component';

@NgModule({
    declarations: [
        StudentLoginComponent,
        StudentClassroomLoginComponent,
        StudentImageLoginComponent,
        StudentAccessComponent,
        StudentNoAccessComponent,
    ],
    imports: [StudentPortalSharedModule, StudentPortalFeatureModule],
})
export class CustomLoginModule {}
