<div class="container">
    <div class="card">
        <div class="header">
            Congrats!
        </div>
        <div class="text">
            You've reached the end of the course. Good job!
        </div>
        <br />
        <div class="text">
            You can close this tab.
        </div>
    </div>
</div>
