export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    UserRoles = 3,
    Audit = 4,
    AccessRequests = 5,
    Modules = 6,
    Sections = 7,
    Students = 8,
    AdminPortal = 9,
    AdminPortal_SendPasswordResetEmails = 10,
    Classrooms = 11,
    Classrooms_CreateClassroomsForOtherTeachers = 12,
    AdministrateFromWordpress = 13,
    ActivationCodes = 14,
    ActivationCodes_OtherThanSelf = 15,
    StudentLogins = 16
}
