import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IStudentClassroomLoginResponse } from '../interfaces/student-classroom-login-response';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { AuthConfig, AuthService, ILoginResponse, OnLoginHandler } from '@mt-ng2/auth-module';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IStudentLoginImage } from '../interfaces/student-login-image';
import { IStudentLogin } from '@model/interfaces/student-login';

@Injectable({
    providedIn: 'root',
})
export class AuthStudentService {
    constructor(
        public http: HttpClient,
        private environmentService: EnvironmentService,
        private authService: AuthService,
        private authConfig: AuthConfig,
    ) {}

    classroomLogin(classroom: string, nickname: string): Observable<IStudentClassroomLoginResponse> {
        const data = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: '',
            Classroom: classroom,
            Nickname: nickname,
        };


        return this.http.post<IStudentClassroomLoginResponse>(`/authStudents/login/validate-classroom`, data);
    }

    imageLogin(studentId: number, imageId: number): Observable<ILoginResponse> {
        const data = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            ImageId: imageId,
            StudentId: studentId,
        };

        return this.http.post<ILoginResponse>(`/authStudents/login/validate-student`, data).pipe(tap((response) => this.handleLogin(response)));
    }

    getAvailableLoginsForClassroom(classroomId: number): Observable<IStudentLogin[]> {
        return this.http.get<IStudentLogin[]>(`/authStudents/get-available-logins-for-classroom/${classroomId}`);
    }

    getAllStudentLogins(): Observable<IStudentLogin[]> {
        return this.http.get<IStudentLogin[]>('/authStudents/get-all-logins');
    }

    getStudentLoginImages(studentId: number): Observable<IStudentLoginImage[]> {
        const data = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            StudentId: studentId,
        };

        return this.http.post<IStudentLoginImage[]>(`/authStudents/login/images`, data);
    }

    studentAccess(key: string): Observable<ILoginResponse> {
        const data = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            StudentToken: key,
        };
        return this.http.post<ILoginResponse>(`/authStudents/student-access`, data).pipe(tap((response) => this.handleLogin(response)));
    }

    private handleLogin(response: ILoginResponse): void {
        this.authService.saveToken(response, true);
        const loginHandler: OnLoginHandler = this.authConfig.eventHandlers.onLogin;
        if (loginHandler) {
            loginHandler(response);
        }
    }
}
