
export const appPathSlugs = {
    myProfile: 'my-profile',
} as const;

export const appPaths = {
    adminAccess: 'adminaccess',
    forgotPassword: 'forgotpassword',
    home: 'home',
    studentLogin: 'login',
    adminLogin: 'adminlogin',
    resetPassword: 'resetpassword',
    studentAccess: 'studentaccess',
} as const;
